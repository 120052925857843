import React from "react"
/** @jsx jsx */
import { Box, Grid, jsx } from "theme-ui"
const HeaderBlockColor = () => {
  return (
    <Box
      sx={{
        background:
          "linear-gradient(90deg, rgba(224, 11, 82, 1) 0%, rgba(226,30,54,1) 100%)",
        transform: "rotate(-3deg) skew(-3deg) !important",
        py: "120px",
        mt: "-230px",
        mb: 4,
      }}
    ></Box>
  )
}
export default HeaderBlockColor

// Colour contrast
// gradient pink - #E00B52 rgba(224, 11, 82, 1)
// gradient red - #e21e36 rgba(226,30,54,1) 100%)
//
//original
// gradient pink = #f40558 rgba(244, 5, 88, 1)
// gradient red - #e21e36 rgba(226,30,54,1) 100%)
